import React, { useState } from 'react';
import SearchControls from './components/SearchControls';
import Login from './components/Login';
import DataTable from './components/DataTable';
import NoData from './components/NoData';
import NoProfile from './components/NoProfile';
import { Heading, Flex, Text } from '@chakra-ui/react';



function Layout(props) {
    const {       
        fetchData,
        signOut,
        signIn,
        isAuthenticated,
        alias,
        setAlias,
        userData,
        routingProfiles,
        selectedRoutingProfiles,
        setSelectedRoutingProfiles,
        lastUpdateTimestamp,
        activityFilter,
        setActivityFilter,
        agentFilter,
        setAgentFilter,
        filteredData,
    } = props;

    if(isAuthenticated) {
        return (
            <Flex flexDirection="column" alignItems='center' height='99vh' width='99vw' overflowY="scroll">
                <Heading marginBottom={'20px'} size='lg'>Agent Status</Heading>
                {routingProfiles.length > 0 || userData.length > 0 ?
                    <SearchControls
                        fetchData={fetchData}
                        signOut={signOut}
                        routingProfiles={routingProfiles}
                        selectedRoutingProfiles={selectedRoutingProfiles}
                        setSelectedRoutingProfiles={setSelectedRoutingProfiles}
                        agentFilter={agentFilter}
                        setAgentFilter={setAgentFilter}
                        activityFilter={activityFilter}
                        setActivityFilter={setActivityFilter}
                    /> : null}
                               
                {
                    routingProfiles.length === 0 || userData.length === 0 ? <NoData />
                    : selectedRoutingProfiles.length === 0 ? <NoProfile />       
                    : <DataTable filteredData={filteredData} userData={userData} lastUpdateTimestamp={lastUpdateTimestamp}/>
                }
            </Flex>
        );
    } else {
        return (
           <Login signIn={signIn} alias={alias} setAlias={setAlias}/>
        );
    }
}

export default Layout;
