import React from 'react';

import { Heading, Input, Flex, Button } from '@chakra-ui/react';



function Login({signIn, alias, setAlias}) {

    return (
        <Flex justifyContent='start' alignItems='center' flexDirection="column" height='99vh' overflowY="hidden">
            <Flex justifyContent='space-evenly' alignItems='center' flexDirection="column" height='50vh' overflowY="hidden">
                <Heading>Cornerstone CX</Heading>
                <Flex justifyContent='center' alignItems='center' flexDirection="row" overflowY="hidden">
                    <Input
                        placeholder='Enter your company alias'
                        value={alias}
                        onChange={(e) => setAlias(e.target.value)}
                    
                    >

                    </Input>
                    <Button style={{marginLeft: '15px'}} colorScheme='green' onClick={() => signIn(alias)}>Sign In</Button>
                </Flex>
            </Flex>
        </Flex>
    );
            
}

export default Login;
