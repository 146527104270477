import { 
  Input,
  Icon,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import { MdLogout, MdOutlineRefresh } from 'react-icons/md';
import Select from 'react-select';

function SearchControls({ 

  fetchData,
  signOut,
  routingProfiles,
  selectedRoutingProfiles,
  setSelectedRoutingProfiles,
  agentFilter,
  setAgentFilter,
  activityFilter,
  setActivityFilter,

}) {

  const filterRoutingProfiles = (e) => {
    let tempRoutingProfiles = []
    for(let i = 0; i < e.length; i++){
      tempRoutingProfiles.push(e[i].value)
    }
    setSelectedRoutingProfiles(tempRoutingProfiles)
    console.log(tempRoutingProfiles)
  }
  return (
    <Flex flexDirection='column' height='5%' width='100%' mt='10px'>
      <Flex flexDirection='row' justifyContent='space-evenly' width='100%'>
              
              
              
              <IconButton
                variant='outline'
                colorScheme='green'
                aria-label='refresh'
                size='md'
                icon={<Icon boxSize={7} as={MdOutlineRefresh}/>}
                onClick={fetchData}
              />
              <Select
                isMulti
                name='routingProfiles'
                placeholder="Choose a Routing Profile"
                defaultValue={selectedRoutingProfiles}
                options={routingProfiles}
                // className='basic-multi-select'
                // classNamePrefix='select'
                onChange={(e => filterRoutingProfiles(e))}
                
           
              />
              <Input 
                ml='10px' 
                placeholder='Agent Name'
                value={agentFilter}
                onChange={(e) => setAgentFilter(e.target.value)}
                maxWidth='25%' 
              />
              <Input 
                ml='10px' 
                placeholder='Activity'
                value={activityFilter}
                onChange={(e) => setActivityFilter(e.target.value)}
                maxWidth='25%' 
              />
              <IconButton
                variant='outline'
                colorScheme='red'
                aria-label='log out'
                size='md'
                icon={<Icon boxSize={7} as={MdLogout}/>}
                onClick={signOut}
              />
              
      </Flex>
    </Flex>
    
  );
}

export default SearchControls;
