import { useState, useEffect } from 'react';
import { Box, Flex, TableContainer, Table, Thead, Tbody, Tr, Th, Td, Heading } from '@chakra-ui/react';

function DataTable({ filteredData, lastUpdateTimestamp }) {
  const headers = ["Agent", "Status", "Time"];
  
  const [dataChunks, setDataChunks] = useState([[], [], []]);

  useEffect(() => {

    // Split data into three chunks
    const chunkSize = Math.ceil(filteredData.length / 3);
    const chunks = [];
    for (let i = 0; i < 3; i++) {
      chunks.push(filteredData.slice(i * chunkSize, (i + 1) * chunkSize));
    }
    setDataChunks(chunks);
  }, [filteredData]);

  const dateConvert = (isoDateStr) => {
    const isoDate = new Date(isoDateStr);
    const now = new Date();
    const diff = now - isoDate;

    const hours = Math.floor(diff / 3600000);
    const minutes = Math.floor((diff % 3600000) / 60000);
    const seconds = Math.floor((diff % 60000) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  const renderTable = (dataChunk, index) => (
    <TableContainer 
      key={index} 
      flex="1"
      borderLeft={index !== 0 ? "1px solid gray" : "none"} // Add left border to all tables except the first
      borderRight="1px solid gray" // Add right border to all tables 
      >
      <Table size='sm' width='32vw'>
        <Thead>
          <Tr>
            {headers.map(header => (
              <Th key={header}>{header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {dataChunk.map((item, rowIndex) => (
            <Tr key={rowIndex}>
              {headers.map(header => (
                <Td
                  key={header}
                  bg={header === 'Status' ? item.Color : 'white'}
                >
                  {header === 'Time' ? dateConvert(item[header]) : item[header]}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );

  return (
    <Box overflowX="auto">
      <Heading size='sm' textAlign='left' mt='10px' marginLeft='30px'>Last Updated: {lastUpdateTimestamp}</Heading>
      <Flex>
        {dataChunks.map((chunk, index) => renderTable(chunk, index))}
      </Flex>
    </Box>
  );
}

export default DataTable;
