import React from 'react';
import { Flex, Text, Heading } from '@chakra-ui/react';

function NoProfile({}) {


    return (

            <Flex
                p="10px"
                mt="20px"
                borderRadius="10px"
                width="100%"
                height='100%'
                justifyContent='center'
                alignItems='center'
                flexDirection="column"
            >
                <Heading >No Routing Profile</Heading>
                <Text mt='25px'>Choose one or more routing profiles above to monitor agents</Text>
            </Flex>

    );
}

export default NoProfile;
