import React, {useState, useEffect} from 'react'
import Layout from './Layout';
import axios from 'axios';

function App() {

  //move to a cfg file or env var. Base URL for get company alias infra
  const aliasUrl = 'https://l1vwabn93l.execute-api.ap-southeast-2.amazonaws.com/default/getCompanyAlias';

  //state elements for managing the authentication flow  
  const [alias, setAlias] = useState('')
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [serverState, setServerState] = useState({});
  const [agentData, setAgentData] = useState([]);
  const [userData, setUserData] = useState({});
  const [routingProfiles, setRoutingProfiles] = useState([]);
  const [selectedRoutingProfiles, setSelectedRoutingProfiles] = useState([]);
  const [fetchIntervalId, setFetchIntervalId] = useState(null);
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(null);
  const [agentFilter, setAgentFilter] = useState('');
  const [activityFilter, setActivityFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);


  //Manage the authentication State based on Code URL param or Alias URL param
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    let code = searchParams.get('code');
    let base64EncodedString= searchParams.get('state');
    let aliasVar = searchParams.get('alias');
    if(aliasVar){
      window.history.replaceState({}, document.title, window.location.pathname);
      signIn(aliasVar)
    }
    if (code && base64EncodedString) {
        console.log("found code and state");
        // Step 1: Decode base64-encoded string to byte array
        const byteArray = new Uint8Array(atob(base64EncodedString).split('').map(char => char.charCodeAt(0)));
        
        // Step 2: Convert byte array to string
        const decodedString = new TextDecoder().decode(byteArray);
        
        // Step 3: Parse string back into an object
        const codeState = JSON.parse(decodedString);
        console.log('code state:', codeState)
        getCompanyData(codeState)
        setIsAuthenticated(true)
        window.history.replaceState({}, document.title, window.location.pathname);
        
    } 
  }, [setIsAuthenticated]);

  useEffect(() => {
    console.log('fetch interval triggered')
    console.log('selected routing profiles length: ', selectedRoutingProfiles.length)
    console.log('user data length: ', Object.keys(userData).length)
    // Clear any existing interval
    if (fetchIntervalId) {
      clearInterval(fetchIntervalId);
    }
  
    // Check if selectedRoutingProfiles and userData are not empty
    if (selectedRoutingProfiles.length > 0 && Object.keys(userData).length > 0) {
      // Set up a new interval
      const intervalId = setInterval(fetchData, 3000); // 3 seconds interval
      setFetchIntervalId(intervalId);
    }
  
    return () => {
      // Clear interval when component unmounts or dependencies change
      if (fetchIntervalId) {
        clearInterval(fetchIntervalId);
      }
    };
  }, [selectedRoutingProfiles, userData]);

   //Live filter the data based on mutile combined filter fields
  useEffect(() => {
    groomData(agentData);
  }, [agentFilter, activityFilter, agentData]); // Refilter data when any filter state changes


  const groomData = (inputData) => {
    let newData = []
    for(let i =0; i < inputData.length; i++){
      let thisAgent = userData[inputData[i].User.Id] || 'Unamed Agent'
      let thisStatus = inputData[i].Contacts.length > 0 ? `${inputData[i].Contacts[0].AgentContactState} - ${inputData[i].Contacts[0].Channel}`: inputData[i].Status.StatusName
      let thisTime = inputData[i].Status.StatusStartTimestamp
      let thisColor

      switch (true) {
        case thisStatus.includes("Available"):
          thisColor = 'green.200'
          break;
        case thisStatus.includes("VOICE"):
          thisColor = 'blue.200'
          break;
        case thisStatus.includes("CHAT"):
          thisColor = 'blue.200'
          break;
        default:
          thisColor = 'yellow.200'
          break;
      }

      let thisRow = {Agent: thisAgent, Status: thisStatus, Time: thisTime, Color: thisColor}
      newData.push(thisRow)
    }
    

    if (agentFilter) {
      newData = newData.filter(item =>
        item.Agent.toLowerCase().includes(agentFilter.toLowerCase())
      );
    }

    if (activityFilter) {
      newData = newData.filter(item =>
        item.Status.toLowerCase().includes(activityFilter.toLowerCase())
      );
    }

    setFilteredData(newData);
    
  };
 
  const fetchData = async () => {
    if (selectedRoutingProfiles.length === 0 || userData.length === 0) {
      return; // Exit if dependencies are not met
    }
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://${serverState.apiGatewayDomain}.execute-api.ap-southeast-2.amazonaws.com/prod/AgentStatus?routingProfiles=${selectedRoutingProfiles}`,    
      headers: { 
    
        'x-api-key': serverState.interactionsKey
    
      }
    
    };    
    axios.request(config)
    
    .then((response) => {
      setAgentData(response.data.UserDataList);
      setLastUpdateTimestamp(new Date().toLocaleTimeString())
    
    })
    
    .catch((error) => {
    
      console.log(error);
    
    });
  }
  const fetchUsers = async (apiGatewayDomain, interactionsKey) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://${apiGatewayDomain}.execute-api.ap-southeast-2.amazonaws.com/prod/ConnectUsers`,    
      headers: { 
    
        'x-api-key': interactionsKey
    
      }
    
    };    
    axios.request(config)
    
    .then((response) => {
      let tempUserData = response.data.UserSummaryList
      let tempUserMap = {}
      for(let i = 0; i < tempUserData.length; i++){
        tempUserMap[tempUserData[i].Id] = tempUserData[i].Username
      }
      setUserData(tempUserMap)
      console.log(tempUserMap)
    
    })
    
    .catch((error) => {
    
      console.log(error);
    
    });
  }
  const fetchRoutingProfiles = async (apiGatewayDomain, interactionsKey) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://${apiGatewayDomain}.execute-api.ap-southeast-2.amazonaws.com/prod/RoutingProfiles`,    
      headers: { 
    
        'x-api-key': interactionsKey
    
      }
    
    };    
    axios.request(config)
    
    .then((response) => {
      let tempRoutingProfiles = []
      for(let i = 0; i < response.data.length; i++){
        tempRoutingProfiles.push({value: response.data[i].Id, label: response.data[i].Name})
      }  
      setRoutingProfiles(tempRoutingProfiles)
      console.log(tempRoutingProfiles)
    
    })
    
    .catch((error) => {
    
      console.log(error);
    
    });
  }
  
  //process login to Cognito - Will redirect page away to cognito hosted UI. Depends on code URL param being receioved and firing useEffect above
  const signIn = async (aliasVar) => {
    const envResult = await axios.get(`${aliasUrl}?companyAlias=${aliasVar}`);
    const envData = btoa(JSON.stringify(envResult.data));
    if(envResult.data === 'Error'){
        alert('Error with company Alias')
        console.log(envResult)
    } else {
      const ssoUrl = `${envResult.data.cognitoDomain}/oauth2/authorize?client_id=${envResult.data.appClientId}&response_type=code&scope=openid&redirect_uri=${envResult.data.agentStatusRedirectUri}&state=${envData}&identity_provider=COGNITO`;
      window.location.href = ssoUrl;
    }
  };

  //sign user out of Cognito
  const signOut = async () => {
    const logoutUrl = `${serverState.cognitoDomain}/logout?client_id=${serverState.appClientId}&logout_uri=${window.location}&redirect_uri=${window.location}&response_type=code`;
    window.location.href = logoutUrl;
  };

  //use the connectionDetails lambda/API gateway URL rteturned into server state by getCompanyAlias request.
  // fetch additional AWS account specifics such as database names and API gateway routes. 
  //Merge results into server state variuable. This is performed AFTER authentication and therefore this data will persist in application state until logout or page refresh.
  const getCompanyData = async (codeState) => {
      console.log("fetching company data")
      const companyResult = await axios.get(codeState.connectionUrl);
      const companyData = companyResult.data;
      let mergedData = {...codeState, ...companyData}
      console.log('full company data retreived')
      setServerState(mergedData)
      fetchUsers(mergedData.apiGatewayDomain, mergedData.interactionsKey)
      fetchRoutingProfiles(mergedData.apiGatewayDomain, mergedData.interactionsKey)
  }
  

  return (
    
      <Layout 
        fetchData={fetchData}
        userData={userData}
        signOut={signOut}
        signIn={signIn}
        isAuthenticated={isAuthenticated}
        alias={alias}
        setAlias={setAlias}
        routingProfiles={routingProfiles}
        selectedRoutingProfiles={selectedRoutingProfiles}
        setSelectedRoutingProfiles={setSelectedRoutingProfiles}
        lastUpdateTimestamp={lastUpdateTimestamp}
        agentFilter={agentFilter}
        setAgentFilter={setAgentFilter}
        activityFilter={activityFilter}
        setActivityFilter={setActivityFilter}
        filteredData={filteredData}
      />
  )
}

export default App;
