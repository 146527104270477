import React from 'react';
import { Flex, Text, Heading } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';

function NoData({}) {


    return (

            <Flex
                p="10px"
                mt="20px"
                borderRadius="10px"
                width="100%"
                height='100%'
                justifyContent='center'
                alignItems='center'
                flexDirection="column"
            >
                <Heading >Fetching Environment</Heading>
                <Text mt='25px'>Collecting Routing Profile and Agent Data from AWS</Text>

                <Spinner
                    thickness="15px"
                    speed="0.65s"
                    color="green.200"
                    h="240px"
                    w="240px"
                    mt="5%"
                />
            </Flex>

    );
}

export default NoData;
